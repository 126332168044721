const simpleMapper = array => array.map(c => ({ value: c.uuid, label: c.name }));
const userMapper = array => array.map(c => ({ value: c.uuid, label: `${c.first_name} ${c.last_name}` }));
const recruiterMapper = array => array.map(c => ({ value: c.uuid, label: c.full_name }));
const citiesMapper = states => {
  const cities = [];
  states.forEach(s => {
    s.cities.forEach(c => {
      cities.push({ value: encodeURI(c), label: s.name === 'Default state' ? c : `${c}, ${s.name}` });
    });
  });
  return cities;
};
const addedByMapper = (array, s) =>
  'technical_user'.includes(s)
    ? [...userMapper(array), { value: 'technical_user', label: 'Technical User' }]
    : userMapper(array);

export const optionsMappers = {
  service: simpleMapper,
  team_lead: userMapper,
  jobs: data => simpleMapper(data.jobs),
  speciality: simpleMapper,
  tags: simpleMapper,
  companies: simpleMapper,
  country: options => options.map(c => ({ value: c.uuid, label: c.name, cities: citiesMapper(c.states) })),
  city: c => c,
  responsible_recruiters: recruiterMapper,
  interviewed_by: userMapper,
  added_by: addedByMapper,
};
