import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Card, Popup, Table } from '_atoms';
import { Context } from '_templates/AnalyticsDashboard/analytics-dashboard';
import { analyticWidgets } from 'actions/analyticActions';
import { openingMap } from '_templates/utils';
import { ReactComponent as Upload } from 'dist/icons/upload.svg';
import { ReactComponent as Empty } from 'dist/images/common/icons/changelog.svg';
import moment from 'moment';
import { columns } from '../AnalyticsDashboard/OpeningsTable';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from '../../constants/text';

export const INITIAL_VALUES = {
  sorting: { key: 'job_name', order: 'asc', field: 'Job name' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};

export const AnalyticsOpeningInfo = () => {
  const { appliedFilters } = useContext(Context);
  const [openings, setOpenings] = useState({ active: [], expected: [], created: [], summary: [] });
  const [options, setOptions] = useState({
    active: INITIAL_VALUES,
    expected: INITIAL_VALUES,
    created: INITIAL_VALUES,
    summary: INITIAL_VALUES,
  });
  const [tableOpeningsLoading, setTableOpeningsLoading] = useState({});
  const [exportPopupOpen, setExportPopupOpen] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);

  const isFirstRender = useRef({ active: true, expected: true, created: true, summary: true });

  const updateOpeningsTableParams = (table, params) => {
    setOptions(p => ({ ...p, [table]: { ...p[table], ...params } }));
  };

  const exportExcel = () => {
    const periods = [];
    if (appliedFilters.expected?.length) {
      periods.push({
        period_filter: 'Expected',
        from_date: appliedFilters.expected[0].format('YYYY-MM-DD'),
        to_date: appliedFilters.expected[1].format('YYYY-MM-DD'),
      });
    }
    if (appliedFilters.active?.length) {
      periods.push({
        period_filter: 'Active',
        from_date: appliedFilters.active[0].format('YYYY-MM-DD'),
        to_date: appliedFilters.active[1].format('YYYY-MM-DD'),
      });
    }
    if (appliedFilters.created?.length) {
      periods.push({
        period_filter: 'Created at',
        from_date: appliedFilters.created[0].format('YYYY-MM-DD'),
        to_date: appliedFilters.created[1].format('YYYY-MM-DD'),
      });
    }
    analyticWidgets('openings/export/xlsx', {
      ...appliedFilters,
      periods,
      sorting: [
        { field: options.expected.sorting.field, order: options.expected.sorting.order, period_filter: 'Expected' },
        { field: options.active.sorting.field, order: options.active.sorting.order, period_filter: 'Active' },
        { field: options.created.sorting.field, order: options.created.sorting.order, period_filter: 'Created at' },
      ],
    }).then(() => {
      setSuccessPopupOpen(true);
      setExportPopupOpen(false);
    });
  };

  const getOpenings = (tablesToUpdate, loadSummary) => {
    const { period, active, expected, created, ...filters } = appliedFilters;
    const minPeriod = [active?.[0], expected?.[0], created?.[0]].filter(d => !!d);
    const maxPeriod = [active?.[1], expected?.[1], created?.[1]].filter(d => !!d);
    tablesToUpdate.forEach(d => {
      if (!appliedFilters[d]?.length) {
        setOpenings(o => ({ ...o, [d]: [] }));
        return;
      }
      setTableOpeningsLoading(l => ({ ...l, [d]: true }));
      analyticWidgets(
        `openings/${d}`,
        {
          ...filters,
          period: [appliedFilters[d][0], appliedFilters[d][1]],
          sorting: [{ field: options[d].sorting.field, order: options[d].sorting.order }],
        },
        null,
        `?limit=${options[d].limit.value}&offset=${options[d].page - 1}`
      )
        .then(resp => {
          updateOpeningsTableParams(d, { itemsCount: resp.count, next: !!resp.next, prev: !!resp.previous });
          setOpenings(o => ({ ...o, [d]: openingMap(resp) }));
        })
        .finally(() => {
          setTableOpeningsLoading(l => ({ ...l, [d]: false }));
        });
    });

    if (loadSummary) {
      setTableOpeningsLoading(l => ({ ...l, summary: true }));
      const summeryPeriods = [];
      if (expected?.length) {
        summeryPeriods.push({
          period_filter: 'Expected',
          from_date: expected[0].format('YYYY-MM-DD'),
          to_date: expected[1].format('YYYY-MM-DD'),
        });
      }
      if (active?.length) {
        summeryPeriods.push({
          period_filter: 'Active',
          from_date: active[0].format('YYYY-MM-DD'),
          to_date: active[1].format('YYYY-MM-DD'),
        });
      }
      if (created?.length) {
        summeryPeriods.push({
          period_filter: 'Created at',
          from_date: created[0].format('YYYY-MM-DD'),
          to_date: created[1].format('YYYY-MM-DD'),
        });
      }
      analyticWidgets(
        `openings/summary`,
        {
          ...filters,
          period: [moment.min(minPeriod), moment.max(maxPeriod)],
          sorting: [{ field: options.summary.sorting.field, order: options.summary.sorting.order }],
          summary_periods: summeryPeriods,
        },
        null,
        `?limit=${options.summary.limit.value}&offset=${options.summary.page - 1}`
      )
        .then(resp => {
          updateOpeningsTableParams('summary', { itemsCount: resp.count, next: !!resp.next, prev: !!resp.previous });
          setOpenings(o => ({ ...o, summary: openingMap(resp) }));
        })
        .finally(() => {
          setTableOpeningsLoading(l => ({ ...l, summary: false }));
        });
    }
  };

  useEffect(() => {
    if (isFirstRender.current.active) {
      isFirstRender.current.active = false;
    } else {
      getOpenings(['active']);
    }
  }, [options.active.offset, options.active.limit, options.active.sorting, options.active.page, appliedFilters]);

  useEffect(() => {
    if (isFirstRender.current.expected) {
      isFirstRender.current.expected = false;
    } else {
      getOpenings(['expected']);
    }
  }, [
    options.expected.offset,
    options.expected.limit,
    options.expected.sorting,
    options.expected.page,
    appliedFilters,
  ]);

  useEffect(() => {
    if (isFirstRender.current.created) {
      isFirstRender.current.created = false;
    } else {
      getOpenings(['created']);
    }
  }, [options.created.offset, options.created.limit, options.created.sorting, options.created.page, appliedFilters]);

  useEffect(() => {
    if (isFirstRender.current.summary) {
      isFirstRender.current.summary = false;
    } else {
      getOpenings([], true);
    }
  }, [options.summary.offset, options.summary.limit, options.summary.sorting, options.summary.page, appliedFilters]);

  if (!openings.active.length && !openings.expected.length && !openings.created.length) {
    return <AnalyticsOpeningsEmpty />;
  }

  return (
    <div className="analytics-openings">
      <Button onClick={() => setExportPopupOpen(true)} icon={<Upload />} className="export-excel">
        {BUTTON_TEXT.EXPORT_EXCEL}
      </Button>
      {!!appliedFilters.active?.length &&
        (openings.active.length ? (
          <Table
            rowKey="uuid"
            cardTitle="Active openings"
            cardCount={options.active.itemsCount}
            columns={columns(options.active.sorting, sorting => updateOpeningsTableParams('active', { sorting }))}
            data={openings.active}
            limit={options.active.limit}
            page={options.active.page}
            updateParams={(key, val) =>
              setOptions(o => ({
                ...o,
                active: {
                  ...o.active,
                  [key]: val,
                  offset: key === 'page' ? (val - 1) * o.active.limit.value : o.active.offset,
                },
              }))
            }
            itemsCount={options.active.itemsCount}
            loading={tableOpeningsLoading.active}
          />
        ) : (
          <AnalyticsOpeningsEmpty title="Active openings" />
        ))}
      {!!appliedFilters.expected?.length &&
        (openings.expected.length ? (
          <Table
            rowKey="uuid"
            cardTitle="Openings by expected date"
            cardCount={options.expected.itemsCount}
            columns={columns(options.expected.sorting, sorting => updateOpeningsTableParams('expected', { sorting }))}
            data={openings.expected}
            limit={options.expected.limit}
            page={options.expected.page}
            updateParams={(key, val) =>
              setOptions(o => ({
                ...o,
                expected: {
                  ...o.expected,
                  [key]: val,
                  offset: key === 'page' ? (val - 1) * o.expected.limit.value : o.expected.offset,
                },
              }))
            }
            itemsCount={options.expected.itemsCount}
            loading={tableOpeningsLoading.expected}
          />
        ) : (
          <AnalyticsOpeningsEmpty title="Openings by expected date" />
        ))}
      {!!appliedFilters.created?.length &&
        (openings.created.length ? (
          <Table
            rowKey="uuid"
            cardTitle="Openings by creation date"
            cardCount={options.created.itemsCount}
            columns={columns(options.created.sorting, sorting => updateOpeningsTableParams('created', { sorting }))}
            data={openings.created}
            limit={options.created.limit}
            page={options.created.page}
            updateParams={(key, val) =>
              setOptions(o => ({
                ...o,
                created: {
                  ...o.created,
                  [key]: val,
                  offset: key === 'page' ? (val - 1) * o.created.limit.value : o.created.offset,
                },
              }))
            }
            itemsCount={options.created.itemsCount}
            loading={tableOpeningsLoading.created}
          />
        ) : (
          <AnalyticsOpeningsEmpty title="Openings by creation date" />
        ))}
      {appliedFilters.active?.length + appliedFilters.expected?.length + appliedFilters.created?.length > 2 &&
        (openings.summary.length ? (
          <Table
            rowKey="uuid"
            cardTitle="Openings summary"
            cardCount={options.summary.itemsCount}
            columns={columns(options.summary.sorting, sorting => updateOpeningsTableParams('summary', { sorting }))}
            data={openings.summary}
            limit={options.summary.limit}
            page={options.summary.page}
            updateParams={(key, val) =>
              setOptions(o => ({
                ...o,
                summary: {
                  ...o.summary,
                  [key]: val,
                  offset: key === 'page' ? (val - 1) * o.summary.limit.value : o.summary.offset,
                },
              }))
            }
            itemsCount={options.summary.itemsCount}
            loading={tableOpeningsLoading.summary}
          />
        ) : (
          <AnalyticsOpeningsEmpty title="Openings summary" />
        ))}
      <Popup
        open={exportPopupOpen}
        title={POPUP_LABELS.EXPORT_EXCEL}
        cancelButtonProps={{ onClick: () => setExportPopupOpen(false), children: BUTTON_TEXT.GO_BACK }}
        okButtonProps={{ onClick: exportExcel, children: BUTTON_TEXT.SEND_TO_EMAIL }}
      >
        {POPUP_BODY.EXPORT_EXCEL}
      </Popup>
      <Popup
        open={successPopupOpen}
        title={POPUP_LABELS.SUCCESS}
        confirmTxt={BUTTON_TEXT.OK}
        okButtonProps={{ onClick: () => setSuccessPopupOpen(false), children: BUTTON_TEXT.OK }}
      >
        {POPUP_BODY.FILE_SENT}
      </Popup>
    </div>
  );
};

const AnalyticsOpeningsEmpty = ({ title }) => (
  <Card className="analytics-openings__empty">
    {!!title && <h2>{title}</h2>}
    <Empty />
    <h3>No data found</h3>
    <p>Please try another search criteria</p>
  </Card>
);
